import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backgroundWrapper: {
    minHeight: '420px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
  },
  contestInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    width: '100%',
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contestName: {
    fontWeight: 700,
    color: theme.palette.common.white,
    padding: theme.spacing(1, 1, 0.5, 1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      padding: 0,
      lineHeight: '30px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '30px',
    },
  },
  smallContestName: {
    fontSize: 36,
    fontWeight: 700,
    color: theme.palette.common.white,
    padding: theme.spacing(1, 1, 0.5, 1),
    textAlign: 'center',
  },
  smallContestOwner: {
    fontSize: 24,
    fontWeight: 600,
    padding: theme.spacing(1, 1, 1, 1),
  },
  tagsWrapper: {
    marginBottom: '2%',
  },
  lastPublished: {
    color: '#fff',
  },
  contestDescription: {
    color: '#fff',
    fontWeight: '400',
    fontSize: '20px',
    maxWidth: '60%',
    textAlign: 'center',
  },
  flag: {
    marginRight: '1%',
  },
  projectsNumWrapper: {
    marginTop: '2%',
  },
  projectsNum: {
    color: '#fff',
    fontSize: '18px',
    fontWeight: '600',
  },
  actionButtons: {
    position: 'absolute',
    bottom: '5%',
    right: '5%',
    zIndex: 3,
  },
  moreActionButton: {
    color: '#fff',
  },
  button: {
    '&:not(.disabled)': {
      color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        color: '#575757',
      },
    },
    '&.Mui-disabled': {
      color: '#989898',
    },
    borderColor: theme.palette.common.white,
    marginRight: theme.spacing(2),
    textTransform: 'none',
    borderRadius: '6px',
    borderWidth: '1px',
    '&:hover': {
      background: 'transparent',
      borderWidth: '2px',
      '& *': {
        fontWeight: 'bold',
      },
    },
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.white}`,
    padding: theme.spacing(0.25, 1.5),
    [theme.breakpoints.down('sm')]: {
      '& *': { fontSize: 13, color: '#575757' },
      color: '#575757',
      borderColor: '#575757',
      padding: theme.spacing(0.5, 1),
      marginLeft: theme.spacing(0),
      minWidth: 120,
      marginRight: 0,
    },
  },
  interactionsWrapper: {
    padding: theme.spacing(1.5, 1.5, 1),
    zIndex: 3,
    position: 'absolute',
    bottom: 0,
    '& svg': {
      color: '#fff',
      fontSize: 20,
    },
    '& h6': {
      color: '#fff',
      fontSize: 13,
      paddingLeft: '8px',
    },
  },
  interactionsRow: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
  },
  interactionsIcon: {
    color: 'inherit',
    marginRight: '5px',
  },
  interactionsText: {
    color: 'inherit',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.6)',
  },
  mobileInteractionsWrapper: {
    position: 'absolute',
    zIndex: 3,
    width: '100%',
    bottom: 0,
  },
}));

export default useStyles;
