/* eslint-disable max-len */
const NODE_ENV = process.env.NODE_ENV || 'development';

const isDevelopment = NODE_ENV === 'development';

const rootPath = isDevelopment ? 'localhost:3000/' : 'https://projectcocoon.org/';

export const META_DATA = {
  HOME: {
    title: 'Cocoon',
    description: 'All About Projects',
    image: 'https://cocoon-icon.s3.us-west-2.amazonaws.com/cocoon.png',
    url: rootPath,
  },
  ABOUT: {
    title: 'About us - Project Cocoon place to showcase your projects and build your profiles',
    description:
      'Project Cocoon is a community of project makers helps bring your ideas to reality. In here, we offer showcase your projects, build your profiles.',
    image: 'https://cocoon-icon.s3.us-west-2.amazonaws.com/cocoon.png',
    url: rootPath + 'about',
  },
  CONTACT: {
    title: 'Contact',
    description: 'Contact us',
    image: 'https://cocoon-icon.s3.us-west-2.amazonaws.com/cocoon.png',
    url: rootPath + 'contact',
  },
  CONTEST_PARTNER: {
    title: 'Contest Page',
    description: 'This is contest space',
    image: 'https://cocoon-icon.s3.us-west-2.amazonaws.com/cocoon.png',
    url: rootPath + 'partners/contest',
  },
  EDIT_CONTEST_PARTNER: {
    title: 'Edit Contest',
    description: 'Edit contest',
    image: 'https://cocoon-icon.s3.us-west-2.amazonaws.com/cocoon.png',
    url: rootPath + 'partners/contest/:id/:name/edit',
  },
  CONTEST_PARTNER_PROFILE: {
    title: 'Partner Profile',
    description: 'This is contest space profile',
    image: 'https://cocoon-icon.s3.us-west-2.amazonaws.com/cocoon.png',
    url: rootPath + 'partners/profile',
  },
  COOKIE_PRACTIVES: {
    title: 'How we use cookie',
    description: 'This is contest space profile',
    image: 'https://cocoon-icon.s3.us-west-2.amazonaws.com/cocoon.png',
    url: rootPath + 'how-we-use-cookies',
  },
  SIGN_IN: {
    title: 'Sign In',
    description: 'Sign In Page',
    image: 'https://cocoon-icon.s3.us-west-2.amazonaws.com/cocoon.png',
    url: rootPath + 'signin',
  },
  SIGN_UP: {
    title: 'Sign Up',
    description: 'Sign Up Space',
    image: 'https://cocoon-icon.s3.us-west-2.amazonaws.com/cocoon.png',
    url: rootPath + 'signup',
  },
  RESET_PASSWORD: {
    title: 'Reset Password',
    description: 'Reset Password',
    image: 'https://cocoon-icon.s3.us-west-2.amazonaws.com/cocoon.png',
    url: rootPath + 'reset-password',
  },
  COOKIE: {
    title: '',
    description: '',
    image: '',
    url: '',
  },
  PROJECT_NEW: {
    title: 'Projects New',
    description: 'New Projects showcase',
    image: 'https://cocoon-icon.s3.us-west-2.amazonaws.com/cocoon.png',
    url: rootPath + '/projects/new',
  },
  PROJECTS: {
    title: 'Projects Place',
    description: 'Projects showcase',
    image: 'https://cocoon-icon.s3.us-west-2.amazonaws.com/cocoon.png',
    url: rootPath + '/projects',
  },
  MY_PROJECTS: {
    title: 'My Project Place',
    description: 'My Projects showcase',
    image: 'https://cocoon-icon.s3.us-west-2.amazonaws.com/cocoon.png',
    url: rootPath + '/projects',
  },
};
