import { List, ListItem, ListItemIcon, ListItemText, Grid, Box, Typography, Button, Popover } from '@material-ui/core';
import { EmojiFlags as ProjectsIcon, Edit as EditIcon } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import DescriptionIcon from '@material-ui/icons/Description';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { bool, object } from 'prop-types';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Tags, CocoonStats } from 'components';
import { SameUserAlt } from 'components/auth/UserAuthorization';
import { PROFILE_TYPES, DIALOG } from 'helpers/constants';
import { handleOpenDialogAC3 } from 'reducers/testAC3/cache';
import { getDiffNow } from 'helpers/date';

import contestHeaderStyle from './contestHeaderStyle';

const MIN_HEIGHT = 490;
const MIN_HEIGHT_MOBILE = 350;

const ContestHeader = ({ desktop, contest }) => {
  const classes = contestHeaderStyle();
  const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  function getContestStats({
    viewsCount,
    contestSponsorsCount,
    contestFollowersCount,
    contestCuratorsCount,
    contestProjectsCount,
  }) {
    return {
      viewsCount,
      contestSponsorsCount,
      contestFollowersCount,
      contestCuratorsCount,
      contestProjectsCount,
    };
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleOpenSubmitProjectDialog = (e) => {
    e.preventDefault();
    const options = contest;

    handleOpenDialogAC3(DIALOG.SUBMIT_PROJECTS_TO_CONTEST, options);
  };

  const handleOpenContestInfoCard = (e) => {
    e.preventDefault();
    const options = contest;

    handleOpenDialogAC3(DIALOG.CONTEST_INFO_CARD, options);
  };

  const navigateToEditPage = () => {
    history.push(`${location.pathname}/edit`);
  };

  const isPassedDeadline = contest.contestDeadline && new Date() > new Date(contest.contestDeadline);

  return (
    <>
      <Grid
        className={classes.backgroundWrapper}
        style={{
          justifyContent: desktop ? 'center' : 'initial',
          backgroundImage: `url(${contest.assetLink})`,
          backgroundSize: 'cover',
          backgroundColor: '#000',
          minHeight: desktop ? MIN_HEIGHT : MIN_HEIGHT_MOBILE,
        }}
      >
        <Box className={classes.contestInfoWrapper}>
          <Typography variant="h2" className={desktop ? classes.contestName : classes.smallContestName}>
            {contest.name}
          </Typography>
          {!desktop && (
            <Typography variant="h3" className={classes.smallContestOwner}>
              Created by {contest.creator.partnerName}
            </Typography>
          )}
          <Grid container justify="center" alignItems="center" className={classes.tagsWrapper}>
            {desktop && <Tags desktop={desktop} isChips isLightView label="tagName" tags={contest.tags} />}
            <Typography variant="body1" className={classes.lastPublished}>
              Published {getDiffNow(contest.updatedAt)}
            </Typography>
          </Grid>
          {desktop && (
            <Typography variant="body1" className={classes.contestDescription}>
              {contest.description}
            </Typography>
          )}
          {desktop && (
            <Grid container justify="center" alignItems="center" className={classes.projectsNumWrapper}>
              <ProjectsIcon style={{ fontSize: '35px' }} fill="#fff" className={classes.flag} />
              <Typography variant="body1" className={classes.projectsNum}>
                {contest.projects.length} projects
              </Typography>
            </Grid>
          )}
        </Box>
        {desktop ? (
          <Box className={classes.actionButtons}>
            {SameUserAlt(+contest.creator?.id)(
              <>
                <Button aria-describedby={id} disableRipple onClick={handleClick}>
                  <MoreHorizIcon className={classes.moreActionButton} />
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  onClose={handleClose}
                >
                  <List>
                    <ListItem button onClick={navigateToEditPage}>
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText>Edit Contest</ListItemText>
                    </ListItem>
                    {/* <ListItem button>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </ListItem> */}
                  </List>
                </Popover>
              </>,
              null,
            )}
            <Button
              onClick={handleOpenContestInfoCard}
              component="span"
              variant="outlined"
              className={classes.button}
              startIcon={<DescriptionIcon fill="#fff" />}
            >
              <Typography variant="button" color="inherit">
                Rules & Prizes
              </Typography>
            </Button>
            <Button
              onClick={handleOpenSubmitProjectDialog}
              component="span"
              variant="outline"
              className={classes.button}
              disabled={isPassedDeadline}
              startIcon={!isPassedDeadline && <AddIcon fill="#fff" />}
            >
              <Typography variant="button" color="inherit">
                {isPassedDeadline ? 'Submission Closed' : 'Submit'}
              </Typography>
            </Button>
            <Button component="span" variant="outlined" className={classes.button}>
              <Typography variant="button" color="inherit">
                Follow
              </Typography>
            </Button>
          </Box>
        ) : (
          <div className={classes.mobileInteractionsWrapper}>
            <CocoonStats profileType={PROFILE_TYPES.CONTEST_PROFILE} desktop={desktop} {...getContestStats(contest)} />
          </div>
        )}
        <div className={classes.overlay} />
      </Grid>
    </>
  );
};

ContestHeader.propTypes = {
  desktop: bool.isRequired,
  contest: object.isRequired,
};

export default ContestHeader;
